@use '@sparbanken-syd/sparbanken-syd-theme/theme' as spb;
@use 'variables' as vars;
@include spb.set-typography;


// Remove annoying 5px margin
body, html {
  margin: 0;
}

h1,
h2,
h3,
h4 {
  margin-bottom: 7px !important;
  margin-top: 15px !important;
}

.spb-holder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0 40px;
}

.spb-content-holder {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  max-width: vars.$spb-max-width;
}

/*
Header in tables
*/
th {
  color: rgb(0 0 0 / 54%) !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  white-space: nowrap !important;
}


/**
Hide scrollbar in table
 */
.mat-mdc-tab-body-content {
  overflow: hidden !important;
}

/**
All icons must me centred instead of appearing a bit to the left in their "boxes"
 */
button {
  .mat-icon {
    margin: 0 !important;
  }
}

a {
  color: #e6e5e4;
}

// We cannot, ever, have blue links.
a, a:visited {
  color: #232323;
}
