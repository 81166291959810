$spb-yellow: #fedd46;
$spb-green: #00960a;
$spb-grey: #f3f2f1;
$spb-pink: #edd7cc;
$spb-blue: #d7e9f5;
$spb-bright-blue: #0069ff;
$spb-red: #be0005;
$spb-small: 766px;
$spb-medium: 996px;
$spb-max-width: 1170px;

@mixin icon-size($icon-size) {
  font-size: $icon-size;
  height: $icon-size;
  min-height: $icon-size;
  width: $icon-size;
  min-width: $icon-size;
}
